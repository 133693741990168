<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Postulaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA EL MODAL DE POSTULACION EXPORT -->
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-postulacion-export"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.reportes.postulacionExport'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  id="postulaciones"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>
                        Empresa
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.slct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Sitio</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_sitio"
                          placeholder="Sitio"
                          label="nombre"
                          :options="sitios"
                          :filterable="false"
                          @search="buscarSitios"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        <label>Nominacion</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="listasForms.slct_nominacion"
                          placeholder="Nominacion"
                          label="nombre"
                          :options="nominaciones"
                          :filterable="false"
                          @search="buscarNominaciones"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(postulacion, index) in postulaciones.data"
                      :key="index"
                    >
                      <td>
                        {{ index + 1 }}
                      </td>
                      <td>
                        {{
                          postulacion.empresa != null
                            ? postulacion.empresa.razon_social
                            : "No encontrada"
                        }}
                      </td>
                      <td>
                        {{ postulacion.solicitud_diaria.fecha }}
                      </td>
                      <td>
                        {{ postulacion.solicitud_diaria.sitio.nombre }}
                      </td>
                      <td>
                        {{ postulacion.solicitud_diaria.nominacion.nombre }}
                      </td>
                      <td class="text-center">
                        <div
                          v-for="estado in postulacion.solicitud_diaria
                            .solicitudes_diaria_empresas"
                          :key="estado.id"
                        >
                          <span
                            class="badge"
                            :class="[
                              estado.estado == 1
                                ? 'badge-warning'
                                : 'badge-success',
                            ]"
                            v-if="estado.empresa_id == postulacion.empresa_id"
                          >
                            {{ estado.nEstado }}
                          </span>
                        </div>
                      </td>
                      <td style="width: 50px" class="text-center">
                        <div v-if="postulacion.empresa.contratos.length == 0">
                          <span
                            class="badge badge-warning"
                            style="font-size: 0.8em"
                          >
                            Contrato<br />Inhabilitado
                          </span>
                        </div>
                        <div class="btn-group float-center" v-else>
                          <button
                            type="button"
                            :class="
                              new Date(
                                postulacion.solicitud_diaria.fecha + ' 00:00'
                              ) >= new Date()
                                ? 'btn btn-sm bg-navy'
                                : 'btn btn-sm bg-secondary'
                            "
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.postulaciones.show'
                              )
                            "
                            @click="
                              edit(
                                postulacion.id,
                                postulacion.empresa_id,
                                postulacion.solicitud_diaria.id
                              )
                            "
                          >
                            <i
                              :class="
                                new Date(
                                  postulacion.solicitud_diaria.fecha + ' 00:00'
                                ) >= new Date()
                                  ? 'fas fa-edit'
                                  : 'fas fa-eye'
                              "
                            ></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="postulaciones.total">
                  <p>
                    Mostrando del <b>{{ postulaciones.from }}</b> al
                    <b>{{ postulaciones.to }}</b> de un total:
                    <b>{{ postulaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="postulaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <PostulacionExport ref="PostulacionExport" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import PostulacionExport from "./PostulacionExport";

export default {
  name: "PostulacionIndex",
  components: {
    pagination,
    vSelect,
    PostulacionExport,
  },
  data() {
    return {
      distancia: null,
      postulaciones: {},
      hoy: new Date(),
      filtros: {
        empresa_id: null,
        sitio_id: null,
        fecha: null,
        userEmpresa: null,
        nominacion_id: null,
      },
      listasForms: {
        slct_empresa: [],
        estados: [],
        slct_nominacion: [],
      },
      nominaciones: [],
      sitios: [],
      slct_sitio: [],
      empresas: [],
    };
  },
  methods: {
    getIndex(page = 1) {
      this.getUserEmpresa();
      this.filtros.empresa_id = null;
      this.filtros.nominacion_id = null;
      if (this.listasForms.slct_empresa) {
        this.filtros.empresa_id = this.listasForms.slct_empresa.id;
      }
      if (this.slct_sitio) {
        this.filtros.sitio_id = this.slct_sitio.id;
      }
      if (this.listasForms.slct_nominacion) {
        this.filtros.nominacion_id = this.listasForms.slct_nominacion.id;
      }
      axios
        .get("/api/hidrocarburos/postulaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.postulaciones = response.data;
        });
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.sitios = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarNominaciones(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/hidrocarburos/nominacion/list?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.nominaciones = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    // Se busca las empresas realcionadas al usuario
    getUserEmpresa() {
      this.filtros.userEmpresa = [];
      for (const iterator of this.$store.getters.getUser.empresas) {
        this.filtros.userEmpresa.push(iterator.id);
      }
    },

    getEstados() {
      axios.get("/api/lista/36").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    edit(
      solicitud_diaria_empresa_id,
      empresa_id,
      solicitud_diaria_id,
      estado_id,
      fecha
    ) {
      return this.$router.push({
        name: "/Hidrocarburos/PostulacionForm",
        params: {
          solicitud_diaria_empresa_id,
          empresa_id,
          solicitud_diaria_id,
        },
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
  },
};
</script>
